/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Twój zakres obowiązków"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "prowadzenie biura i bieżąca obsługa klientów"), "\n", React.createElement(_components.li, null, "w﻿spółpraca z działem handlowym i logistyką"), "\n", React.createElement(_components.li, null, "k﻿reowanie pozytywnego wizerunku firmy"), "\n", React.createElement(_components.li, null, "ofertowanie i składanie zamówień"), "\n"), "\n", React.createElement(_components.h2, null, "Nasze wymagania"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "wykształcenie min. średnie"), "\n", React.createElement(_components.li, null, "k﻿omunikatywność i otwartość"), "\n", React.createElement(_components.li, null, "d﻿yspozycyjność"), "\n", React.createElement(_components.li, null, "z﻿aangażowanie i motywacja do pracy"), "\n", React.createElement(_components.li, null, "w﻿ysoka kultura osobista"), "\n", React.createElement(_components.li, null, "u﻿miejętność pracy w zespole"), "\n", React.createElement(_components.li, null, "prawo jazdy kat. B."), "\n"), "\n", React.createElement(_components.h2, null, "Oferujemy"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "s﻿tabilne warunki zatrudnienia w firmie o ugruntowanej pozycji na rynku"), "\n", React.createElement(_components.li, null, "z﻿atrudnienie w oparciu o umowę o pracę"), "\n", React.createElement(_components.li, null, "m﻿ożliwość rozwoju zawodowego"), "\n", React.createElement(_components.li, null, "n﻿iezbędne narzędzia do wykonywania pracy"), "\n", React.createElement(_components.li, null, "j﻿asny i atrakcyjny system wynagradzania oraz premiowanie za osiągnięte cele sprzedażowe"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
