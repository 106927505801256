import GATSBY_COMPILED_MDX from "/opt/build/repo/content/kariera/asystent-biura-handlowego-toruń.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import {MDXProvider} from "@mdx-js/react";
import Seo from '../components/seo';
import AnimationWrapper from '../components/animationWrapper';
import ApplicationForm from '../components/applicationForm';
import ContactTile from '../components/contactTile';
import Container from '../components/container';
import Header from '../components/header';
import InnerSection from '../components/innerSection';
import Layout from '../components/layout';
import "./listingPage.css";
const ListingPage = ({data, location, children}) => {
  const header = data.page.frontmatter.header;
  header.title = data.listing.frontmatter.title;
  const file = data.listing.internal.contentFilePath.split('/').pop();
  const listingSlug = file.slice(0, file.lastIndexOf('.'));
  const metaDescription = data.listing.frontmatter.meta_description || `Czamaninek ➤ ${data.listing.frontmatter.title} ⭐ Czytaj!`;
  const MyH2 = props => React.createElement("h2", Object.assign({
    className: "section-heading"
  }, props));
  const components = {
    h2: MyH2
  };
  return React.createElement(Layout, {
    location: location,
    path: listingSlug
  }, React.createElement(Seo, {
    title: `${data.listing.frontmatter.title} - ${data.page.frontmatter.title}`,
    description: metaDescription
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    data: data.page.frontmatter.header
  })), React.createElement("section", {
    id: "listing-description",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(AnimationWrapper, {
    animationType: "fadeIn"
  }, React.createElement("div", {
    className: "section-description listing-description"
  }, React.createElement(MDXProvider, {
    components: components
  }, children))))), React.createElement("section", {
    id: "listing-application",
    className: "page-section"
  }, React.createElement(Container, null, React.createElement(InnerSection, {
    className: "contact-data application-form-wrapper"
  }, React.createElement("div", {
    className: "application-form-container"
  }, React.createElement(AnimationWrapper, null, React.createElement("h2", {
    className: "section-heading"
  }, "Formularz aplikacyjny")), React.createElement(ApplicationForm, {
    position: data.listing.frontmatter.slug
  })), React.createElement(ContactTile, {
    contact: data.contactGlobal.frontmatter
  })))));
};
export const query = graphql`
  query(
        $slug: String,
      ) {
        page: mdx(frontmatter: { slug: { eq: "listing-page" }, type: { eq: "page" } }) {
          frontmatter{
            title
            header{
              title
              breadcrumbs {
                title
                url
              }
              overlay
              image {
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: {fit: COVER},
                    webpOptions: {quality: 95}
                  )
                }
              }
            }
          }
        }
        listing: mdx(frontmatter: {slug: {eq: $slug }}) {
          frontmatter {
            title
            meta_description
            header{
              title
              breadcrumbs {
                title
                url
              }
              overlay
              image {
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: {fit: COVER},
                    webpOptions: {quality: 95}
                  )
                }
              }
            }
          }
          internal {
            contentFilePath
          }
        }
        contactGlobal: mdx(
          frontmatter: {slug: {eq: "contact-global"}, type: {eq: "global"}}
        ){
          frontmatter{
            name
            phone
            phone_postfix
            phone_alt
            email
            address{
              street
              postal_code
              locality
              region
            }
          }
        }
      }
`;
ListingPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ListingPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
