import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen, faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image";

import AnimationWrapper from "../components/animationWrapper"
import "./contactTile.css"

const ContactTile = ({ contact }) => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        footer: mdx(
          frontmatter: {slug: {eq: "footer"}, type: {eq: "global"}}
        ){
          frontmatter{
            logo{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    `
  )

  return (
    <AnimationWrapper className="contact-tile-container" animationType="fadeInRight">
      <div className="contact-tile">
          {data.footer.frontmatter.logo &&
            <div className="logo-container">
              <GatsbyImage
                image={data.footer.frontmatter.logo.childImageSharp.gatsbyImageData}
                alt={data.site.siteMetadata.title}
              />
            </div>
          }
        <br />
        {contact.name &&
          <div className="section-description-small">{contact.name}</div>
        }
        <ul className="contact-info-wrapper">
          {contact.address?.street &&
            <li>
              <FontAwesomeIcon icon={faMapMarker} />
              <AnchorLink
                to="/kontakt#location-map-pin"
                title={`${contact.address?.street}, ${contact.address?.postal_code} ${contact.address?.locality}`} />
            </li>
          }
          {contact.email &&
            <li><FontAwesomeIcon icon={faEnvelopeOpen} /><a href={`mailto:${contact.email}`}> {contact.email}</a></li>
          }
          {contact.phone &&
            <li><FontAwesomeIcon icon={faPhone} />
              <div>
                <a href={`tel:${contact.phone}`}> {contact.phone}</a><br />
                {contact.phone_alt &&
                  <a href={`tel:${contact.phone_alt}`}> {contact.phone_alt}</a>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    </AnimationWrapper>
  )
}

export default ContactTile